@import url('https://fonts.googleapis.com/css?family=Montserrat:700');
@import url('https://fonts.googleapis.com/css?family=Source+Serif+Pro:400,600');

html {
  font-family: 'Source Serif Pro', serif;
  line-height: 1.6;
}

@media (min-width: 40rem) {
  html {
    font-size: 1rem;
  }
}

.project-link {
  background-color: #0074d9;
  padding: 4px;
  border-radius: 4px;
}

.project-link a {
  font-family: 'Source Serif Pro', serif;
  color: #fff;
  font-size: 0.9rem;
}

.project-title {
  font-weight: 700;
  margin-bottom: 10px;
}

body {
  margin: 2rem 0 5rem;
  color: #333;
}

@media (min-width: 40rem) {
  body {
    margin-top: 5rem;
  }
}

a {
  color: #0074d9; /* From http://clrs.cc */
  text-decoration: none;
}

a:hover, a:focus {
  text-decoration: underline;
}

p, table {
  font-family: 'Source Serif Pro';
}

h1, h2, h3, h4, h5, h6 {
  font-family: Montserrat, sans-serif;
  margin: 0 0 0.5rem -0.1rem /* align left edge */;
  line-height: 2;
  color: #111;
  text-rendering: optimizeLegibility;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

@media (min-width: 30rem) {
  h1 {
    font-size: 3rem;
    margin-bottom: 3rem;
  }
}

h1 a {
  color: inherit;
}

h2 {
  margin-top: 2rem;
  font-size: 1.25rem;
  margin-bottom: 0.75rem;
}

@media (min-width: 30rem) {
  h2 {
    margin-top: 2.5rem;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
}

h3 {
  margin-top: 1.5rem;
  font-size: 1.1rem;
  margin-bottom: 0.75rem;
}

h4, h5, h6 {
  margin-top: 1rem;
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

p, ul, ol, dl, table, pre, blockquote {
  margin-top: 0;
  margin-bottom: 1rem;
}

ul, ol {
  font-family: 'Source Serif Pro', serif;
  padding-left: 1.5rem;
}

dd {
  margin-left: 1.5rem;
}

blockquote {
  margin-left: 0;
  margin-right: 0;
  padding: .5rem 1rem;
  /*border-left: .25rem solid #ccc;*/
  color: #666;
}

blockquote p:last-child {
  margin-bottom: 0;
}

hr {
  border: none;
  margin: 25px 0;
  border-bottom: 1px solid #ccc;
  position: relative;
  top: -1px;
}

.container img, .container iframe {
  max-width: 100%;
}

.container img {
  margin: 0 auto;
  display: block;
}

.post-list {
  border: none;
}

.post-list td {
  border: none;
  vertical-align: top;
}

td.post-date {
  white-space: nowrap;
  padding: 0;
}

td.post-title {
  padding: 0;
}

td.post-list-divider {
  padding: 0 10px 0 10px;
}

table {
  width: 100%;
  border: 1px solid #ccc;
  border-collapse: collapse;
}

td, th {
  padding: .25rem .5rem;
  border: 1px solid #ccc;
  vertical-align: top;
}

th {
  background-color: #eee;
}

pre, code {
  font-family: inherit;
  background-color: #eee;
}

pre {
  padding: .5rem 1rem;
  font-size: 0.9rem;
}

code {
  padding: .1rem .25rem;
}

pre > code {
  padding: 0;
}

.container {
  max-width: 40rem;
  margin: 0 auto;
  padding: 0 1rem;
}

.menu-bar a {
  color: #111;  
  text-transform: lowercase;
}

.page {
  margin-top: 2.5rem;
}

/* Projects Page */

.project-list {
  list-style: none;
  padding: 0;
}

.project-links {
  margin-bottom: 0.8rem;
}

.project-images-clear {
  clear: both;
}

.project-image {
  float: left;
  max-width: 49%;
  overflow: auto;
}

.project-image + .project-image {
  margin-left: 2%;
}

.project-elsewhere {
  margin-bottom: 25px;
}

/*.preface {
  background-color: #eee;
  padding: .5rem 1rem 0.15rem 1rem;
  margin-bottom: 1rem;
}*/

.double {
  border-bottom: 5px solid #ccc;
}